import './config';
import {EmergencyOrdersConfig} from "@/config/_EmergencyOrdersConfig";
import {Tracer} from "@api/common/Tracer";

/**
 * Represents the main configuration object for the app.
 */
export class AppConfig {

    constructor(config) {
        this.__$legacyConfig = config;
        this.__$emergencyOrders = new EmergencyOrdersConfig();
    }

    get ApiUrl(){
        return process.env.VUE_APP_API_URL;
    }


    get isLocal(){
        return window.config.isLocal;
    }

    get environment(){
        return window.config.environment;
    }

    get correlationId() {
        return window.config.correlationId;
    }

    getLocaleUrl(locale) {

        if (locale == null) {
            locale = "";
        }

        const tmpLocale = locale.toLowerCase();
        switch(tmpLocale){
            case "en-us":
                return process.env.VUE_APP_LOCALE_URL_EN_US;
            case "fr-ca":
                return process.env.VUE_APP_LOCALE_URL_FR_CA;
            default:
                return process.env.VUE_APP_LOCALE_URL_EN_US;
        }
    }

    getPosthogConfig() {
       return {
           key : process.env.VUE_APP_POSTHOG_KEY,
           url : process.env.VUE_APP_POSTHOG_URL
       }
    }


    get isDebugMode() {

        try{
            return this.config.isDebugMode
        }
        catch(e){
            Tracer.current.debug('[d-652397] configuration object does not have isDebugMode defined');
            return false;
        }

    }

    get source() {
        return this.__$legacyConfig;
    }

    get(key){

        let value = this.__$legacyConfig["key"];
        if (value != null){
            return value;
        }
        else
        {
            return null;
        }
    }


    /**
     * Get the emergency Order config object.
     * @returns {EmergencyOrdersConfig}
     */
    getEmergencyOrderConfigs(){
        return this.__$emergencyOrders;
    }


    /**
     * Gets the current tracer instance.
     * @returns {AppConfig|*}
     */
    static get current() {

        if (window.__app_config == null){
            window.__app_config = new AppConfig(window.config);
        }

        return window.__app_config;
    }
}


