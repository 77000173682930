import axios from 'axios';
import {HttpUtils} from './common/HttpUtils';

export class USPSService {

    constructor(auth){
        this.auth = auth;
        this.baseUrl = `${window.config.api.partsUri}/v1/usps`;
        this.headers = HttpUtils.getHttpHeaders(auth, 'text/json');
        this.error = null;
    }

    setErrorHandler(error){
        this.error = error;
    };

    get httpHeaders(){
        return HttpUtils.getHttpHeaders(this.auth);
    }


    zipCodeLookup(zipcode, result){

        let serviceUrl = `${this.baseUrl}/zipcodes/${zipcode}`;

        axios.get(serviceUrl, {headers: this.headers}).then(response => {

            if (response.status === 200){

                let zipcodes = [];
                response.data.forEach(function (o){

                    let zipCode = new ZipCode(o.zipCode, o.city, o.state, o.stateAbbr);
                    zipcodes.push(zipCode);

                });

                result(zipcodes);
            }

        }).catch( e => {

            result(false);
            let is404 = HttpUtils.handled404(e, func => {result(false)});

            if (!is404) {
                if (!this.error) {
                    HttpUtils.handleError(e);
                } else {
                    this.error(e);
                }
            }
        });
    }

}

class ZipCode {
    constructor(zipcode, city, state, stateAbbr){
        this._zipCode = zipcode;
        this._city = city;
        this._state = state;
        this._stateAbbr = stateAbbr;
    }

    get zipCode(){
        return this._zipCode;
    }

    get city(){
        return this._city;
    }

    get state(){
        return this._state;
    }

    get stateAbbr(){
        return this._stateAbbr;
    }
}


