import {required, minLength, maxLength, requiredIf, email} from "vuelidate/lib/validators";
import {mask} from 'vue-the-mask'
import _ from 'lodash';
import {USStatesService} from '@/api/USStatesService';
import {USPSService} from '@/api/USPSService'
import {Address} from "./models/Address";
import {UserManagement} from "@/api/modules/userManagement/UserManagement";
import {StoreProxy} from "@/api/common/StoreProxy";
import ViewHeader from "../../../components/ViewHeader/ViewHeader.vue";
import {UserPermissions} from "@api/core/UserPermissions";
import {Tracer} from "@api/common/Tracer";
import {VinValidator} from "@api/common/VinValidator";


const vinNumberValidator = (value) => {
    if (value === null) return true;
    if (value === "")return true;
    return VinValidator.validate(value);
}

export default {
    name: "PlaceOrder",
    directives: {
        mask: mask
    },
    components: {
        ViewHeader: ViewHeader,
    },
    beforeRouteEnter (to, from, next) {

        next(vm => {

            let type = to.params.type;

            if (from.name === "place-order-confirm"){
                type = from.params.type;
                vm._loadShipping(from.params.order.shipping, from.params.order.details);
            }

            if (type == null){
                type = 'standard';
            }

            vm.type = type;
            Tracer.current.debug(`7VHAYP-(place_order): place order for type: ${vm.type}`);

            vm._setGrandTotal();
            vm._loadCreditLimit();

        });
        next();
    },
    beforeRouteUpdate (to, from, next){

        let vm = this;

        let type = to.params.type;

        if (from.name === "place-order-confirm"){
            type = from.params.type;
            vm._loadShipping(from.params.order.shipping, from.params.order.details);
        }

        if (type == null){
            type = 'standard';
        }

        vm.type = type;
        Tracer.current.debug(`ULTTN-(place_order)[order_type=${vm.type}]`);

        vm._setGrandTotal();
        vm._loadCreditLimit();


        next();
    },
    beforeRouteLeave (to, from, next){
        next();
    },
    created() {

        this.auth = StoreProxy.getAuth(this.$store);
        let vm = this;
        this.isOverCreditLimitRestricted = UserPermissions.isAccountOverCreditRestricted(this.$store);
        this._profile = StoreProxy.getProfile(this.$store);
        this.dealerCode = this._profile.account.dealerCode;

        vm._load();


    },
    mounted() {
    },
    data(){
        return {
            _$uspsService: null,
            isLoading: false,
            creditLimitLoaded: false,
            type: 'standard',
            billing: new Address(),
            shipping: new Address(),
            originalShipping: new Address(),
            purchaseOrder: '',
            vinNumber: '',
            comments: '',
            attentionTo: '',
            phoneNumber: '',
            dealerCode: '',
            email: '',
            isDifferentShippingAddress: false,
            shippingAddressChangeEnabled: false,
            grandTotal: 0,
            shippingView: 'normal',
            formError: false,
            lookupZipCode: '',
            lookupAddresses: [],
            availableCredit: 0,
            isOverCreditLimitRestricted: false,
            creditAlertMessage: '',
            showCreditAlertMessage: false
        }
    },
    validations : {
        billing: {
            customer: {required},
            address: {required},
            address2: {},
            city: {required},
            state: {required},
            zipCode: {
                    required,
                    minLength: minLength(5),
                    maxLength: maxLength(10)
            }
        },
        shipping: {
            customer: {required},
            address: {required},
            address2: {},
            city: {required},
            state: {required},
            zipCode: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(10)
            }
        },
        purchaseOrder: { required },
        vinNumber: {
            required: requiredIf(function(model) {
               return false;
            }),
            vinNumber: vinNumberValidator

        },
        email: {
            required: requiredIf(function(model) {
                return this.isEmergency;
            }),
            email
        },
        dealerCode: {
            required: requiredIf(function(model) {
                return this.isEmergency;
            })
        },
        comments: {},
        attentionTo: {
            required: requiredIf(function(model) {
                return this.isDifferentShippingAddress;
        })},
        phoneNumber: {
            required: requiredIf(function(model) {
                return this.isDifferentShippingAddress;
            })}
    },
    computed:{

       states(){
           return USStatesService.getStates();
       },
       billingState(){

           if (this.billing.state === '')return;

           let stateList = USStatesService.getStates();
           let billing = this.billing;

           let state = _.find(stateList, function(o){
             return  o.id === billing.state;
           });

           if (state){
              return state.name;
           }
       },

       disableConfirmButton(){

           if (this.grandTotal <= 0){
               return true;
           }

           if (this.isOverCreditLimitRestricted){
               return (this.grandTotal > this.availableCredit);
           }

           return false;
       },
        isEmergency(){
           return this.type === 'emergency';
        }


    },
    watch: {
        creditLimitLoaded(_new, _old){
          if (_new === true){
              this._checkCreditLimit();
          }
      }
    },
    methods: {
        _load(){


            let profile = this.$store.getters.getProfile;
            let billingAddress = profile.billingAddress;
            let shippingAddress = profile.shippingAddress;

            this.billing.setAddress(
                                    billingAddress.customerName,
                                    billingAddress.address1,
                                    billingAddress.address2,
                                    billingAddress.city,
                                    billingAddress.state,
                                    billingAddress.zipCode);

            this.originalShipping.setAddress(
                                    shippingAddress.customerName,
                                    shippingAddress.address1,
                                    shippingAddress.address2,
                                    shippingAddress.city,
                                    shippingAddress.state,
                                    shippingAddress.zipCode);

            this.shipping.setAddress(
                                    shippingAddress.customerName,
                                    shippingAddress.address1,
                                    shippingAddress.address2,
                                    shippingAddress.city,
                                    shippingAddress.state,
                                    shippingAddress.zipCode);

            Tracer.current.debug('#ERHPVH:[PLACE_ORDER]:[LOAD_INFO]: Load billing and shipping info from profile');
            this._$uspsService = new USPSService(this.auth);

        },

        _loadShipping(shipping, details) {
            if (details) {
                this.purchaseOrder = details.purchaseOrder;
                this.vinNumber = details.vinNumber;
                this.comments = details.comments;
                this.attentionTo = details.attentionTo;
                this.phoneNumber = details.phoneNumber;
                this.isDifferentShippingAddress = details.isDifferentShippingAddress;

                if (this.isDifferentShippingAddress){
                    this.shipping = shipping;
                }
            }
        },
        onConfirmOrder : function () {


            this.$v.$touch();
            if (this.$v.$invalid){
                Tracer.current.warn("#5LUSVG:[PLACE_ORDER]:!VALIDATION_ERROR!:[MISSING_FIELDS]: Possible missing required fields.");
                this.formError = true;
                return;
            }
            else{
                this.formError = false;
            }

            let order = {
                billing: this.billing,
                shipping: this.shipping,
                details: {
                    purchaseOrder: this.purchaseOrder,
                    vinNumber: this.vinNumber,
                    comments: this.comments,
                    attentionTo: this.attentionTo,
                    phoneNumber: this.phoneNumber,
                    dealerCode: this.dealerCode,
                    email: this.email,
                    isDifferentShippingAddress: this.isDifferentShippingAddress
                }
            };

            this.$router.push({
                        name:'place-order-confirm',
                        params: { order: order, type: this.type}
                    });
        },
        changeShippingAddress(){

            if (this.isDifferentShippingAddress){
                this.shippingView = 'askZip';
                this.shippingAddressChangeEnabled = true;
            }
            else{
                this.shippingView = 'normal';
                this.shippingAddressChangeEnabled = false;
                this.shipping.setAddress(this.originalShipping.customer,
                                        this.originalShipping.address,
                                        this.originalShipping.address2,
                                        this.originalShipping.city,
                                        this.originalShipping.state,
                                        this.originalShipping.zipCode);


            }
        },
        addressLookup(){
          if (this.lookupZipCode.length >= 5){

              this._$uspsService.zipCodeLookup(this.lookupZipCode, result => {

                  if (result.length > 0){
                      this.lookupAddresses = result;
                  }
              });
          }
          else if (this.lookupZipCode.length <5) {

              this.lookupAddresses = [];
          }

        },
        addressSelected(item){

            this.shipping.setAddress('', '', '', item.city, item.stateAbbr, item.zipCode);
            this.shippingView = 'normal'

        },
        onShippingZipCodeFocus(){
            if (this.isDifferentShippingAddress){
                this.shippingView = 'askZip';
                this.lookupZipCode = this.shipping.zipCode;
                this.addressLookup();
            }
        },
        onZipContinue(){
            this.shipping.setAddress('', '', '', '', '', this.lookupZipCode);
            this.shippingView = 'normal'
        },
        vinNumberUpperCase(){
          this.vinNumber = this.vinNumber.toUpperCase();
        },
        _setGrandTotal(){

            if (this.type === 'bulk') {
                this.grandTotal = StoreProxy.getBulkOrderGrandTotal(this.$store);
                window.tracer.debug('#DM8EQK/PLACE_ORDER:[FROM_ORDERS_PARTS]: Loading parts from the orders part (bulk) shopping cart');
            }
            else if (this.type === 'emergency') {
                this.grandTotal = StoreProxy.getEmergencyOrderGrandTotal(this.$store);
                window.tracer.debug('#D3CTEZ/PLACE_ORDER:[FROM_ORDERS_PARTS]: Loading parts from the orders part (emergency) shopping cart');
            }
            else{
                this.grandTotal = this.$store.getters.shoppingCartGrandTotal;
                window.tracer.debug('#6HJJ3/PLACE_ORDER:[FROM_PART_CATALOG] Loading parts from the part catalog shopping cart');
            }

            if (this.grandTotal <= 0){
                window.tracer.warn(`#9ODE7V/PLACE_ORDER:!NO_GRAND_TOTAL!: Grand Total $0.00 for the place order view! - cart type ${this.type}`);
            }

        },
        _loadCreditLimit() {
            let userManagement = new UserManagement(this.auth);

            let vm = this;
            vm.isLoading = true;
            userManagement.getAvailableCredit().then(value => {
                vm.availableCredit = value;
                Tracer.current.debug(`#2GOUUG/PLACE_ORDER:[CHECK_AVAILABLE_CREDIT]:[VALUE:${vm.availableCredit}]`);
                vm.isLoading = false;
                vm.creditLimitLoaded = true;
            }).catch(e => {
                vm.isLoading = false;
                vm.creditLimitLoaded = true;
                Tracer.current.error(e);
            });
        },
        _showCreditLimitExceedAlertMessage() {

            Tracer.current.debug(`#EAQZGM/PLACE_ORDER:[CREDIT_RESTRICTED]: Order submission disabled because account does not have enough credit`);


            this.$alert('Your order exceeded your credit limit, please call 888.AER.0001 to complete your order.', 'Credit Limit Alert', {
                confirmButtonText: 'OK',
                type: "warning"
            }).then(() => {
                this.$router.go(-1);
            }).catch(() => {
                Tracer.current.debug("#8QS2TL/PLACE_ORDER:[ALERT_DIALOG][CANCEL_EVENT]: User cancelled the alert window");
            });

        },
        _checkCreditLimit(){

            let vm = this;

            if (this.isOverCreditLimitRestricted) {
                Tracer.current.debug(`#Y787I/PLACE_ORDER:[PLACE_ORDER]:[IS_CREDIT_LIMIT_RESTRICTED:${this.isOverCreditLimitRestricted}]: Credit Limit Restricted`);

                if (vm.grandTotal > vm.availableCredit){
                    Tracer.current.debug(`#FEXHQ3/PLACE_ORDER:[PLACE_ORDER]:[GRAND_TOTAL:${vm.grandTotal};AVAILABLE_CREDIT:${vm.availableCredit}]: Checked for available credit.`);
                    vm.creditAlertMessage = "Your order exceeded your credit limit, please call 888.AER.0001 to complete your order.";
                    vm.showCreditAlertMessage = true;
                    this._showCreditLimitExceedAlertMessage();
                }
            }
            else{

                Tracer.current.debug(`#FEXHQ3/PLACE_ORDER:[PLACE_ORDER]:[GRAND_TOTAL:${vm.grandTotal};AVAILABLE_CREDIT:${vm.availableCredit}]: Checked for available credit.`);
                if (vm.grandTotal > vm.availableCredit){
                    vm.showCreditAlertMessage = true;
                    this.creditAlertMessage = "Your order exceeded your credit limit. Please call the office";
                }

            }

            const unwatch = this.$watch('creditLimitLoaded', function(){
               Tracer.current.debug("#E56DM2:[UNWATCH:creditLimitLoaded]");
            });
            unwatch();

        }
    }
}