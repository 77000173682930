/**
 * TODOS:
 * 1. Simplify, redo the Year, Make, Model dropdowns. The implementation is a remanent of a more
 *    complex logic from previous releases.
 *
 */

import { ShoppingCart } from "@api/modules/shoppingCart/ShoppingCart";
import { ImageGallery } from "./ImageGallery";
import { SelectModel } from "./SelectModel";
import { CompanySettings } from "@api/modules/userManagement/CompanySettings";
import "simplebar";
import "simplebar/dist/simplebar.css";
import * as constants from "../../../constants";
import {StoreProxy} from "@api/common/StoreProxy";
import {Tracer} from "@api/common/Tracer";
import {UserPermissions} from "@api/core/UserPermissions";
import {CatalogSearch} from "@api/modules/catalogSearch/CatalogSearch";
import {PartCatalogSearch} from "@api/modules/catalogSearch/PartCatalogSearch.ts";
import {CatalogSearchArgs} from "@api/modules/catalogSearch/CatalogSearchArgs";
import {StringUtils} from "@api/common/StringUtils";
import {VinValidator} from "@api/common/VinValidator";

/* components */
import CartConfirmationDialog from "./CartConfirmationDialog/CartConfirmationDialog.vue";
import PartNumberSearch from "./_components/PartNumberSearch/PartNumberSearch.vue";


export default {
    name: "PartsCatalog",

    data() {
        return {

            _$searchService: null,
            _$shoppingCart: null,

            /**
             * Holds the list of year available in the part catalog
             */
            _years: [],
            searchType: "", // VIN or VEHICLE

            vinNumber : "",
            vinCategory: "",
            partNumber: "",
            allCategories: [],
            category: new SelectModel(),
            year: new SelectModel(),
            make: new SelectModel(),
            models: new SelectModel(),
            engine: new SelectModel(),
            driveType: new SelectModel(),
            fuelType: new SelectModel(),
            engineVin: new SelectModel(),
            dropdowns: {
                    yearValue: "",
                    makeValue: "",
                    modelValue: "",
                    isYearDisabled: false,
                    isMakeDisabled: true,
                    isModelDisabled: true
            },
            choices: {
                    driveTypeValue: "",
                    fuelTypeValue: "",
                    engineValue: "",
                    engineVinValue: "",
                    categoryValue: "",
            },
            searchMessage: "",
            maxQty: 50,
            partsResult: [],
            searchBreadCrumbs: "",
            imageGallery: new ImageGallery(),
            compatibleVehiclePartNumber: "",
            vehicles: [],
            inventoryPartNumber: "",
            inventoryBranches: [],
            cartItems: {},
            shoppingCartDialog: {
                part: {},
                show: false
            },

            // flags
            loading: false,
            isModelLoading: false,
            isMakeLoading: false,
            specificOptionsDialogVisible: false,
            categoryDialogVisible: false,
            compatibleVehiclesDialog: false,
            inventoryDialog: false,
            noResults: false,

            // button flag
            isSearchByVehicleEnabled: false,

            // private flags
            _keepSelectedValues: false,
            _hasSpecificOptions: false,
        }
    },
    mounted() {

        let vm = this;
        this.year.setChildren([this.make, this.models, this.category, this.engine, this.engineVin, this.driveType, this.fuelType]);
        this.year.registerSelectedValueCallback(function (value) {
            vm.dropdowns.yearValue = value;
        })

        this.make.setChildren([this.models, this.category, this.engine, this.engineVin, this.driveType, this.fuelType]);
        this.make.registerSelectedValueCallback(function (value) {
            vm.dropdowns.makeValue = value;
        })

        this.models.setChildren([this.category, this.engine, this.engineVin, this.driveType, this.fuelType]);
        this.models.registerSelectedValueCallback(function (value) {
            vm.dropdowns.modelValue = value;
        })

        this.category.setChildren([this.driveType, this.fuelType, this.engine, this.engineVin]);

        this._catalogSearch = new CatalogSearch(StoreProxy.getAuth(this.$store));
        this._partCatalogSearch = new PartCatalogSearch(StoreProxy.getAuth(this.$store));


        this._catalogSearch.getYears().then(years => {
            vm._years = years; //cache for future reload, as years list will not change.
            vm.year.setOptions(years);
        }).catch((err) => {
                Tracer.current.error(err)
                vm._showErrorMessageDialog();
            }
        );

        this._partCatalogSearch.getAllCategories().then(categories => {
            vm.allCategories = categories;
        }).catch(err => {
            Tracer.current.error(err)
            vm._showErrorMessageDialog();
        });

    },
    computed: {
        Tracer() {
            return Tracer
        },
        isDriveTypeOptionVisible() {
            return this.driveType.count > 0;
        },
        isEngineBaseOptionVisible() {
            return this.engine.count > 0;
        },
        isEngineVinOptionVisible() {
            return this.engineVin.count > 0;
        },
        isFuelTypeOptionVisible() {
            return this.fuelType.count > 0;
        },
        isSearchByVinEnabled() {
          return this.vinNumber.length >= 13;
        },
        isSearchByPartNumberEnabled() {
            return this.partNumber.length >= 3;
        },
        hasPartResult() {
          return this.partsResult.length > 0
        },
        allowBackOrders() {
            return UserPermissions.canBackOrder(this.$store);
        },
        categoryOptions() {
            return this.searchType === "VIN" ? this.allCategories : this.category.options;
        }
    },
    methods: {

        getCatalogSearch() {
            if (this._catalogSearch == null) {
                this._catalogSearch = new CatalogSearch(StoreProxy.getAuth(this.$store));
                return this._catalogSearch;
            } else {
                return this._catalogSearch;
            }
        },
        reload(selectObj){
            selectObj.resetChildren();
        },
        /*
         * YEAR related method
         */
        onYearKeydown(e) {

            let item = this.year.findValue(this.dropdowns.yearValue);
            if (item != null){
                this.onYearSelected(item);
            }
            else {
                let firstItem = this.year.getFirstItem()
                if (firstItem != null) {
                    this.dropdowns.yearValue = this.year.getSelectedName();
                    this.onYearSelected(firstItem);
                } else {
                    Tracer.current.debug("1SEJWZ (selections): No item found for this value " + this.dropdowns.yearValue);
                }
            }
        },
        onYearInputChange(e){
            // do nothing.
            // future use.
        },
        onYearSelected(item) {

            if (item == null || item === ""){
                this.make.resetChildren();
                return;
            }

            const vm = this;
            this.clearSearchResultMessage();

            this.isMakeLoading = true;

            const isValid = this.year.setSelectedItem(item);

            if (!isValid){
                Tracer.current.debug("6EHMFO-(catalog)(year_selected): Selected value is not a valid year.");
                return;
            }

            this.year.resetChildren();

            // reset any value on the vin number and interchange section.
            this.vinNumber = '';
            this.partNumber = '';

            const service = this.getCatalogSearch();

            this._enableMake();
            service.getMakes(this.year.getSelectedId()).then( makes => {

                vm.make.setOptions(makes);

                if (vm.make.count > 0) {
                    vm.isMakeLoading = false;
                    this._focusMakeInput();
                }
                else{
                    vm.isMakeLoading = false;
                    vm._disableMake();
                }

            }).catch(err => {

                Tracer.current.error(err);
                vm._disableMake();
                this._showErrorMessageDialog();
                vm.isMakeLoading = false;

            });


        },
        /*
         * MAKE dropdown methods
         */
        onMakeKeydown(e) {

            if (e.shiftKey) {
                this.$refs.year.focus();
                return;
            }

            let item = this.make.findValue(this.dropdowns.makeValue);
            if (item != null) {
                this.onMakeSelected(item);
            } else {
                let firstItem = this.make.getFirstItem()
                if (firstItem != null) {
                    this.dropdowns.makeValue = this.make.getSelectedName();
                    this.onMakeSelected(firstItem);
                } else {
                    Tracer.current.debug("1EB0CU (selections):[MAKE] No item found for this value " + this.dropdowns.makeValue);
                }
            }


        },
        onMakeInputChange(e) {

        },
        onMakeSelected (item) {


            if (item == null || item === ""){
                this.dropdowns.isModelDisabled();
                return;
            }

            this.clearSearchResultMessage();

            let isValid = this.make.setSelectedItem(item);

            if (!isValid){
                Tracer.current.debug("CTTCJN (selections): selected value is not a valid make (or make on the list)");
                return;
            }

            this.make.resetChildren();
            this.isModelLoading = true;

            let vm = this;
            let service = this.getCatalogSearch();

            vm._enableModel();
            service.getModels(this.year.getSelectedId(), this.make.getSelectedId()).then( items  => {

                vm.models.setOptions(items);

                if (vm.models.count > 0) {
                    vm.isModelLoading = false;
                    vm._focusModelInput();

                    Tracer.current.debug(`5W5K99 (selections): ${vm.models.count} models for ${this.year.getSelectedId()} and ${this.make.getSelectedId()}`);
                }
                else{
                    Tracer.current.info(`EGZRC3 (selections): No models found for ${this.year.getSelectedId()} and ${this.make.getSelectedId()}`);
                    vm.isModelLoading = false;
                    vm._disabledModel();
                }
            }).catch(err => {

                vm._disabledModel();
                Tracer.current.error(err);
                this._showErrorMessageDialog();
                vm.isModelLoading = false;

            });
        },
        onModelInputChange(e) {

        },
        onModelKeydown(e) {

            if (e.shiftKey) {
                this.$refs.makeOptions.focus();
                return;
            }

            let item = this.models.findValue(this.dropdowns.modelValue);

            if (item != null) {
                this.onModelSelected(item);
            }
            else {
                let firstItem = this.models.getFirstItem();

                if (firstItem != null) {
                    this.dropdowns.modelValue = this.models.getSelectedName();
                    this.onModelSelected(firstItem);
                } else {

                    this.$refs.modelOptions.focus();
                    Tracer.current.debug("FIO7DL-(part-catalog)[vehicle_model]: No vehicle model found for this value " + this.dropdowns.modelValue);

                }
            }
        },
        /*
         * MODEL dropdown methods
         */
        onModelSelected(item) {

            if (item == null || item === "") {
                this.isSearchByVehicleEnabled = false;
                return;
            }

            this.clearSearchResultMessage();
            let isValid = this.models.setSelectedItem(item);

            if (!isValid){
                Tracer.current.debug("4PGLZP-(part_catalog): selected value is not a valid model (or model on the list)");
                return;
            }

            this.isSearchByVehicleEnabled = true;
            this.$refs.modelOptions.activated = false;
        },
        onSearchClick() {

            let yearId = this.year.getSelectedId();
            let makeId = this.make.getSelectedId();
            let modelId = this.models.getSelectedId();

            this.loading = true;

            this.searchType = "VEHICLE";
            this.searchMessage = "";

            let vm = this;
            let service = this.getCatalogSearch();

            service.getCategories(yearId, makeId, modelId).then(categories => {

                vm.loading = false;

                vm.category.setOptions(categories);

                if (vm.category.count === 1) {
                    vm.categorySelected(categories[0])
                } else if (vm.category.count === 0) {
                    vm.search();
                } else {
                    vm.openCategoryDialog(true);
                }

            }).catch(err => {
                Tracer.current.error(err, {yearId, makeId, modelId});
                vm._showErrorMessageDialog();
                vm.loading = false;
            });

        },
        categorySelected(categoryId){

            if (this.searchType === "VIN"){
               this._categorySelectedForVinSearch(categoryId)
            }
            else{
               this._categorySelectedForVehicleSearch(categoryId);
            }

        },
        showSpecificConditionsDialog () {
            this.specificOptionsDialogVisible = true;
        },
        /**
         * Show a pop-up with category options.
         */
        modifyOptions(){

          if (this._hasSpecificOptions){
              this.showSpecificConditionsDialog();
          }
          else{
              this.openCategoryDialog(true);
          }

        },

        prepareChoice(){

            this.engine.setSelectedValue(this.choices.engineValue);
            this.fuelType.setSelectedValue(this.choices.fuelTypeValue);
            this.driveType.setSelectedValue(this.choices.driveTypeValue);
            this.engineVin.setSelectedValue(this.choices.engineVinValue);

        },
        search() {

            this.closeSpecificOptionsDialog(true);
            this.loading = true;

            this.prepareChoice();

            const vm = this;
            const service = this.getCatalogSearch();

            const searchArgs = new CatalogSearchArgs();
            searchArgs.year = this.year.getSelectedId();
            searchArgs.makeId = this.make.getSelectedId();
            searchArgs.modelId = this.models.getSelectedId();
            searchArgs.categoryId = this.category.getSelectedId();
            searchArgs.engineId = this.engine.getSelectedId();
            searchArgs.vinId = this.engineVin.getSelectedId();
            searchArgs.driveId = this.driveType.getSelectedId();
            searchArgs.fuelId = this.fuelType.getSelectedId();

            this.searchType = "VEHICLE";
            service.searchParts(searchArgs).then(results => {

                const engineValue = vm.engine.getSelectedName();
                const engineVinValue = vm.engineVin.getSelectedName();
                const categoryValue = vm.category.getSelectedName();
                const driveType = vm.driveType.getSelectedName();
                const fuelType = vm.fuelType.getSelectedName();

                vm.searchBreadCrumbs = vm.breadCrumbsTextFormation(
                                                                    vm.year.getSelectedName(),
                                                                    vm.make.getSelectedName(),
                                                                    vm.models.getSelectedName(),
                                                                    categoryValue,
                                                                    engineValue,
                                                                    engineVinValue,
                                                                    driveType,
                                                                    fuelType);

                if (results == null) {
                    vm.partsResults = [];
                    vm.loading = false;
                    vm.searchMessage = "No results found for your criteria.";
                    return;
                }

                vm.partsResult = results.parts;



                if (vm.partsResult.length > 0) {

                    vm.searchMessage = "";

                    for(let i = 0; i < this.partsResult.length; i++)
                    {
                        const cartItem = {

                            quantity : 1,
                            branchId : "",
                            branchName : ""
                        };

                        if (Tracer.current.isDebugEnabled) {
                            Tracer.current.debug(`3VHVYW2-(part_catalog): ${i} = ${this.partsResult[i].partNumber}`);
                        }

                        this.$set(this.cartItems, this.partsResult[i].partNumber,  cartItem);
                    }

                    vm.loading = false;

                }
                else{
                    vm.searchMessage = "No results found for your criteria.";
                    vm.loading = false;
                }

            }).catch(err => {
                Tracer.current.error(err);
                vm._showErrorMessageDialog();
                vm.loading = false;

            });

        },
        searchBack(){

            this.closeSpecificOptionsDialog();
            this.category.resetChildren();
            this.openCategoryDialog(true);

        },
        /**
         * Opens the category dialog
         * @param keepUserSelection Whether the UI keeps the user selection (dropdowns) when the dialog is closed.
         */
        openCategoryDialog(keepUserSelection) {

            if (keepUserSelection == null){
                this._keepSelectedValues = false;
            }
            else{
                this._keepSelectedValues = keepUserSelection;
            }

            this.categoryDialogVisible = true;

        },
        /**
         * Clears search fields.
         * @returns {void}
         */
        clearSearch() {
            this.vinNumber = "";
            this.vinCategory = "";
            this.partNumber = "";
            this.isSearchByVehicleEnabled = false;
            this.searchType = "";
            this.searchMessage = "";
            this.noResults = false;
            this._clearSearchFields();
        },
        /**
         * Clears the year dropdown, and its dependents which are **Make** and **Model** dropdowns.
         */
        clearYear() {
            this._clearSearchFields();
        },
        /**
         * Clear the make dropdown, and its dependent **Model** dropdown
         */
        clearMake(){
            this.dropdowns.makeValue = "";
            this.dropdowns.modelValue = "";
            this.models.resetChildren();
            this._disabledModel();
        },
        /**
         * Clear the model dropdown
         */
        clearModels(force){

            if (!this._keepSelectedValues || (force != null && force)) {
                Tracer.current.debug("DSSZSW (part catalog) > CLEAR MODEL VALUE");
                this.dropdowns.modelValue = "";
            }

        },
        /**
         * Closes the category dialog
         * @param clearModels If `true` then the **models** dropdown will be cleared.
         */
        closeCategoryDialog(clearModels) {

            if (clearModels != null && clearModels) {
                this._keepSelectedValues = false;
                this.clearModels();
            }

            this.categoryDialogVisible = false;
        },
        /**
         * Listen to the `open` event of the **Category** dialog
         * @returns {boolean} - Always returns `true`
         */
        onOpenCategoryDialog() {
            return true;
        },
        /**
         * Listen to the `close` event of the **Category** dialog
         * @returns {boolean} - Always returns `true`
         */
        onCloseCategoryDialog() {
            return true;
        },
        /**
         * List to the *Category* dialog *open* event.
         * @returns {boolean} Always true.
         */
        onOpenSpecificOptionsDialog () {
            return true
        },
        /**
         * Listen to the *Specific Options* dialog *close* event.
         * @returns {boolean} Always true.
         */
        onCloseSpecificOptionsDialog() {

            Tracer.current.debug("6M0APV-(part_catalog) > ON CLOSE EVENT");
            this.clearModels();
            return true;

        },
        /**
         * Closes the dialog that shows the different part options
         * @param keepUserSelection - if true, then keeps the user selection in the user interface, otherwise, clears it.
         */
        closeSpecificOptionsDialog (keepUserSelection) {

            this._keepSelectedValues = keepUserSelection;
            this.specificOptionsDialogVisible = false;

        },
        openInventoryDialog (partNumber, branches) {
            this.inventoryPartNumber = partNumber;
            this.inventoryBranches = branches;
            this.inventoryDialog = true;
        },
        onInventoryDialogClose() {

            this.inventoryDialog = false;
            this.inventoryPartNumber = "";
            this.inventoryBranches = [];

        },

        openCompatibleVehiclesDialog (partNumber){

            let vm = this;
            let service = this.getCatalogSearch();

            service.getCompatibleVehicles(partNumber).then(data => {

                if (data != null && data.count > 0) {
                    vm.vehicles = data.vehicles;
                    vm.compatibleVehiclePartNumber = partNumber;
                    vm.compatibleVehiclesDialog = true;
                }
                else {
                    vm.vehicles = [];
                    vm.compatibleVehiclePartNumber = partNumber;
                    vm.compatibleVehiclesDialog = true;
                }

            }).catch(_ => {

                vm.onCompatibleVehiclesDialogClose();
                vm._showErrorMessageDialog("We are sorry. We have encountered a problem when looking for compatible vehicles for this part number. Please try again later");
            })

        },
        onCompatibleVehiclesDialogClose() {

            this.compatibleVehiclesDialog = false;
            this.compatibleVehiclePartNumber = "";
            this.vehicles = [];

        },
        clearSearchResultMessage() {
            if (this.searchMessage.length > 0) this.searchMessage = "";
        },
        onSelectedBranch(command) {

            if (command.branch.qty === 0){

                let stockInDifferentBranch = false;
                command.part.branches.forEach((branch) =>{
                    if (branch.qty > 0 && branch.id !== command.branch.id){
                        stockInDifferentBranch = true;
                    }
                });

                if (stockInDifferentBranch){
                    const h = this.$createElement;

                    this.$msgbox({
                        title: "Warning",
                        message: h("div", {class: "warning-branch-confirmation-dialog"}, [
                            h("div", null, "This part is out of stock at the location selected. It is in stock at a different location."),
                            h("div", {style: "font-weight: bold; margin-top: 5px;"}, "Do you want to continue?")
                        ]),
                        confirmButtonText: "Yes, Continue",
                        cancelButtonText: "No, Cancel",
                        showCancelButton: true,
                        type: "warning",
                        beforeClose: (action, instance, done) => {
                            if (action === "confirm") {
                                this.cartItems[command.part.partNumber].branchId = command.branch.id;
                                this.cartItems[command.part.partNumber].branchName = command.branch.name;
                                done();
                            } else {
                                done();
                            }
                        }
                    });

                }
                else{

                    if (Tracer.current.isDebugEnabled) {
                        Tracer.current.debug(`31SYSY2-(part_catalog): User selected the branch ${command.branch.name} even when all branch does not have stock.`);
                    }

                    this.cartItems[command.part.partNumber].branchId = command.branch.id;
                    this.cartItems[command.part.partNumber].branchName = command.branch.name;
                }

            }
            else {

                this.cartItems[command.part.partNumber].branchId = command.branch.id;
                this.cartItems[command.part.partNumber].branchName = command.branch.name;
            }
        },
        addToShoppingCart(i){

            if (this._$shoppingCart == null){
                this._$shoppingCart = new ShoppingCart(StoreProxy.getAuth(this.$store), StoreProxy.getUserId(this.$store));
            }

            const part = this.partsResult[i];
            const partNumber = part.partNumber;

            const allowBackOrders = UserPermissions.canBackOrder(this.$store);

            if (!part.inStock && !allowBackOrders)
            {
                this.$alert("Currently, we don't have this part in stock", "PART NOT IN STOCK", {
                    confirmButtonText: "OK",
                    type: "warning",
                    customClass: "not-in-stock-warning-message",
                    dangerouslyUseHTMLString: true
                }).then(() => {
                    // do nothing. We cannot add this item to shopping cart.
                }).catch(() => {
                    Tracer.current.debug("861918-(part_catalog): User cancelled the alert window");
                });
            }
            else if (!part.inStock && allowBackOrders){

                const companySettings = CompanySettings.getCompanyInstance(this.$store);
                const backOrderText = companySettings.getSetting(constants.SETTINGS_BACKORDER_CONFIRMATION_MESSAGE_KEY);

                this.$confirm(backOrderText, "PART NOT IN STOCK", {
                    confirmButtonText: "Yes, Add to my cart",
                    cancelButtonText: "Cancel",
                    type: "warning",
                    customClass: "not-in-stock-warning-message",
                    dangerouslyUseHTMLString: true
                }).then(() => {
                    this.internalAddToCart(part);
                }).catch(() => {
                    Tracer.current.debug(`807839-(part_catalog): User cancelled from adding item ${partNumber} to shopping cart`);
                });
            }
            else {
                this.internalAddToCart(part);
            }

        },
        internalAddToCart(part){

            const partNumber = part.partNumber;
            const description = part.description;
            const branchId = this.cartItems[partNumber].branchId;
            const quantity = this.cartItems[partNumber].quantity;


            this._$shoppingCart.add(this.$store, partNumber, branchId, quantity, description).then(result => {

                this.shoppingCartDialog.part = this.partsResult.find(function (element) {
                    return element.partNumber = partNumber;
                });

                this.shoppingCartDialog.show = true;


            }).catch(rej =>{

                this.$message.error(`Shopping Cart Error. Failed to add part ${partNumber} to your shopping cart.`);
                Tracer.current.warn("845604-(part_catalog): Fail when adding a new item to the shopping cart!.");
                Tracer.current.error(rej);

            });

        },
        onShoppingCartDialogClose(){
            this.shoppingCartDialog.part = {};
            this.shoppingCartDialog.show = false;

            this.$router.push("/shopping-cart");
        },
        getBranches(part){

            if (part.branches == null)return [];

            if(!window.config.excludeZeroInventoryBranches) {
                return part.branches;
            }
            else {

                let tmpBranches = [];
                part.branches.forEach((b) => {

                    if (b.qty > 0 || b.id === tmpBranches) {
                        tmpBranches.push(b);
                    }
                });

                return tmpBranches;
            }

        },

        /** Search parts by vin number */
        vinNumberSearch() {

            if (StringUtils.isEmptyOrNull(this.vinNumber)){

                this.searchMessage = `VIN is required.`;
                this.noResults = true;
                return;
            }

            this.vinNumber = this.vinNumber.trim();
            this.vinNumber = this.vinNumber.toUpperCase();

            const validationResult = VinValidator.validate(this.vinNumber);

            if (!validationResult){
                this.searchMessage = `The VIN ${this.vinNumber} is not valid.`;
                this.noResults = true;
                return;
            }

            const vm = this;
            this.loading = true;

            this.searchType = "VIN";
            this.searchMessage = "";
            this._partCatalogSearch.searchPartsByVin(this.vinNumber, this.vinCategory).then(vehicle => {


                if (vehicle != null) {

                    // find the category name selected
                    let tmpCategoryName = "";

                    for(let i = 0; i < vm.allCategories.length; i++) {
                        if (vm.allCategories[i].id === vm.vinCategory) {
                            tmpCategoryName = vm.allCategories[i].name;
                            break;
                        }
                    }

                    let driveTypeList = "";

                    if (vehicle.driveType.length === 1){
                        driveTypeList = vehicle.driveType[0].name;
                    } else if (vehicle.driveType.length > 1){

                        for(let i = 0; i < vehicle.driveType.length; i++) {

                            const comma = (i === 0) ? "" : ", ";
                            driveTypeList += comma + vehicle.driveType[i].name
                        }
                    }

                    vm.searchBreadCrumbs = `VIN ${this.vinNumber} | `;
                    vm.searchBreadCrumbs = vm.searchBreadCrumbs + vm.breadCrumbsTextFormation(
                        vehicle.year,
                        vehicle.make.name,
                        vehicle.model.name,
                        tmpCategoryName,
                        vehicle.engineLiters.name,
                        vehicle.engineVin.name,
                        driveTypeList,
                        vehicle.fuelType.name);


                    if (vehicle.results.parts.length > 0){
                        vm._displaySearchResults(vehicle.results.parts);
                    }
                    else {

                        vm.partsResult = [];

                        if (tmpCategoryName.length > 0) {
                            vm.searchMessage = `No results found for vin: ${this.vinNumber} for ${tmpCategoryName}`;
                        }
                        else{
                            vm.searchMessage = `No results found for vin: ${this.vinNumber}`;
                        }

                        vm.noResults = true;
                    }
                }
                else {
                    vm.searchMessage = "No vehicle found for vin: " + this.vinNumber;
                    vm.noResults = true;
                }

                this.loading = false;

            }).catch(rej => {
                vm.searchMessage = "There's an error decoding the VIN number.";
                Tracer.current.warn("HBYHW-(part_catalog): There's an error decoding the VIN number.");
                Tracer.current.error(rej);
                this.loading = false;
            })
        },
        breadCrumbsTextFormation: __breadCrumbsTextFormation,
        selectedPart(partNumber){
          this.partNumber = partNumber;

          // reset any value in the VinNumber section.
          this.vinNumber = "";

          // reset any values in the vehicle search dropdown.
          this.clearYear();
        },
        onPartNumberSearch() {

            if (this.partNumber.length === 0) {
                return;
            }

            try {

                this._partNumberSearch(this.partNumber);

            } catch (err) {

                Tracer.current.error(err);
                this.$notify.error({
                    title: `Error while retrieving part ${this.partNumber} information.`,
                });

            }
        },
        clearPart(){
            this.partNumber = "";
        },

        /*
         * *********************************************************
         * PRIVATE METHODS - ONLY TO BE USED WITHIN THIS COMPONENT *
         * *********************************************************
         */

        /**
         * Search parts by the part number
         * @param {string} partNumber
         * @private
         */
        _partNumberSearch(partNumber) {

            if (StringUtils.isEmptyOrNull(partNumber)){

                this.searchMessage = `Part Number required.`;
                this.noResults = true;
                return;
            }

            partNumber = partNumber.trim();
            partNumber = partNumber.toUpperCase();

            const vm = this;
            this.loading = true;

            this.searchMessage = "";
            this._partCatalogSearch.getPartsFromAlias(partNumber).then(results => {

                if (results != null) {
                    
                    vm.searchBreadCrumbs = `PART NUMBER: ${partNumber}`;

                    if (results.parts.length > 0){
                        this.searchType = "PART";
                        vm._displaySearchResults(results.parts);
                    }
                    else {

                        vm.searchMessage = `No results found for part number: ${partNumber}`;
                        vm.noResults = true;
                    }
                }
                else {
                    vm.searchMessage = `No results found for part number: ${partNumber}`;
                    vm.noResults = true;
                }

                this.loading = false;

            }).catch(rej => {
                vm.searchMessage = "There's an error searching for part number.";
                Tracer.current.warn("CMXNY8-(view:parts-catalog): There's an error searching for part number.");
                Tracer.current.error(rej);
                this.loading = false;
            })
        },

        /**
         * Process the user-selected category for VIN search.
         * @param categoryItem
         * @private
         */
        _categorySelectedForVinSearch(categoryItem){
            this.vinCategory = categoryItem.id;
            this.vinNumberSearch();
            this.closeCategoryDialog(false);
        },

        /**
         * Process the user-selected category for vehicle applications.
         * @param categoryItem
         * @private
         */
        _categorySelectedForVehicleSearch(categoryItem) {

            this.category.resetChildren();
            this._clearSpecificOptions();

            this.category.setSelectedItem(categoryItem);

            const tmpYearId = this.year.getSelectedId();
            const tmpMakeId = this.make.getSelectedId();
            const tmpModelId = this.models.getSelectedId();
            const tmpCategoryId = this.category.getSelectedId();

            const vm = this;
            const service = this.getCatalogSearch();

            this.loading = true;
            service.getSpecificConditions(tmpYearId, tmpMakeId, tmpModelId, tmpCategoryId).then( sc => {

                if (sc != null && (
                    sc.driveTypes.length > 0 ||
                    sc.engineLiters.length > 0 ||
                    sc.engineVins.length  > 0 ||
                    sc.fuelTypes.length > 0)) {

                    vm._hasSpecificOptions = true;
                    vm.driveType.setOptions(sc.driveTypes);
                    vm.engine.setOptions(sc.engineLiters);
                    vm.engineVin.setOptions(sc.engineVins);
                    vm.fuelType.setOptions(sc.fuelTypes);
                    vm.showSpecificConditionsDialog();
                }
                else {
                    vm._hasSpecificOptions = false;
                    this.search();    // search parts.
                }

                vm.closeCategoryDialog(false);
                vm.loading = false;

            }).catch(err => {

                Tracer.current.error(err);

                vm.closeCategoryDialog(false);
                vm._showErrorMessageDialog();
                vm.loading = false;

            });

        },
        _displaySearchResults(parts){

            this.partsResult = parts;

            if (this.partsResult.length > 0) {

                this.searchMessage = "";
                for(let i = 0; i < this.partsResult.length; i++)
                {
                    const cartItem = {
                        quantity : 1,
                        branchId : "",
                        branchName : ""
                    };

                    if (Tracer.current.isDebugEnabled) {
                        Tracer.current.debug(`6QXGBN4-(part_catalog): ${i} = ${this.partsResult[i].partNumber}`);
                    }

                    this.$set(this.cartItems, this.partsResult[i].partNumber,  cartItem);
                }

                this.loading = false;

            }
            else{
                this.searchMessage = "No results found for your criteria.";
                this.loading = false;
            }
        },
        _disableMake(){
          this.dropdowns.isMakeDisabled = true;
        },
        _enableMake(){
            this.dropdowns.isMakeDisabled = false;
        },
        _disabledModel(){
            this.dropdowns.isModelDisabled = true;
        },
        _enableModel(){
            this.dropdowns.isModelDisabled = false;
        },
        _focusMakeInput(){
            this.$refs.makeOptions.$forceUpdate();
            this.$refs.makeOptions.focus();
            this.$refs.year.activated = false;
            this.$refs.makeOptions.activated = true;
        },
        _focusModelInput(){
            this.$refs.modelOptions.$forceUpdate();
            this.$refs.modelOptions.focus();
            this.$refs.makeOptions.activated = false;
            this.$refs.modelOptions.activated = true;
        },
        _clearSearchFields() {

            this.dropdowns.yearValue = "";
            this.dropdowns.makeValue = "";
            this.dropdowns.modelValue = "";
            this.make.resetChildren();
            this._disableMake();
            this._disabledModel();

            this.choices.categoryValue = "";
            this._clearSpecificOptions();
            this.category.clear();
            this.engine.clear();
            this.engineVin.clear();
            this.driveType.clear();
            this.fuelType.clear();
            this.partsResult = [];

            Tracer.current.info("DMRQ3U-(part_catalog): search criteria reset");
        },
        _clearSpecificOptions(){
            this.choices.fuelTypeValue = "";
            this.choices.engineValue = "";
            this.choices.driveTypeValue = "";
            this.choices.engineVinValue = "";
        },
        _showErrorMessageDialog(message){

            let msg = message;
            if (message == null){
                msg = __getPageErrorMessage();
            }

            this.$alert(msg, "Parts Catalog Error", {
                confirmButtonText: "OK",
                type: "error"
            }).then(() => {
                //nothing
            }).catch(() => {
                //nothing
            });

        }
    },
    components: {
        PartNumberSearch,
        CartConfirmationDialog
    }
}

function __breadCrumbsTextFormation(year, make, model, category, engine, engineVin, driveType, fuelType) {
    let value = `${year} > ${make} > ${model}`;

    if (typeof category != "undefined" && category.length > 0) {
        value = value + " > " + category;
    }

    if (typeof driveType != "undefined" && driveType.length > 0) {
        value = value + " >  Drive Type " + driveType;
    }

    if (typeof engine != "undefined" && engine.length > 0) {
        value = value + " >  Engine " + engine;
    }

    if (typeof engineVin != "undefined" && engineVin.length > 0) {
        value = value + " > Engine VIN " + engineVin
    }

    if (typeof fuelType != "undefined" && fuelType.length > 0) {
        value = value + " >  Fuel Type " + fuelType;
    }

    return value;
}


function __getPageErrorMessage(){
    return "We are sorry!, we have encountered a technical problem while accessing catalog data. Please try again later";
}
