import axios from 'axios';
import {HttpUtils} from "@/api/common/HttpUtils";
import {PlacedOrderParts} from "../models/PlacedOrderParts";
import {PlacedOrderDetails} from "../models/PlacedOrderDetails";
import {TrackingInfo} from "@api/modules/orderHistory/models/TrackingInfo";

/**
 * Integrates with REST API for order history.
 */
export class OrderHistoryService {

    constructor(auth){
        this._auth = auth;
        this._headers = HttpUtils.getHttpHeaders(auth, 'text/json');
        this._url = `${process.env.VUE_APP_API_URL}/v1/orders`;
    }

    /**
     * Represents a constant for Invoice.
     * Value of 1
     * @type {number}
     */
    static Invoice = 1;

    /**
     * Represents a constant for Credits.
     * Value of 2
     * @type {number}
     */
    static Credits = 2;

    /**
     * Represents a constant for Open Orders.
     * Value of 3
     * @type {number}
     */
    static Open = 3;

    /**
     * Represents a constant for Cancelled Orders.
     * Value of 4
     * @type {number}
     */
    static Cancelled = 4;

    /**
     * Invokes REST API for searching for invoices. It uses the JWT to identify the session and current user.
     * @param searchParams Search parameters
     * @returns {Promise<any>}
     */
    searchInvoices(searchParams){
        let url = `${this._url}/invoices?${this._createParams(searchParams)}`;
        return this._fetchData(url);
    }

    /**
     * Invokes REST API for searching for credits. It uses the JWT to identify the session and current user.
     * @param searchParams Search parameters
     * @returns {Promise<any>}
     */
    searchCredits(searchParams){
        let url = `${this._url}/credits?${this._createParams(searchParams)}`;
        return this._fetchData(url);
    }

    /**
     * Invokes REST API for searching for open orders. It uses the JWT to identify the session and current user.
     * @param searchParams Search parameters
     * @returns {Promise<any>}
     */
    searchOpenOrders(searchParams){
        let url = `${this._url}/open?${this._createParams(searchParams)}`;
        return this._fetchData(url);
    }

    /**
     * Invokes REST API for searching for cancelled orders. It uses the JWT to identify the session and current user.
     * @param searchParams Search parameters
     * @returns {Promise<any>}
     */
    searchCancelledOrders(searchParams){
        let url = `${this._url}/cancelled?${this._createParams(searchParams)}`;
        return this._fetchData(url);
    }

    /**
     * Invokes REST API to get the details of an invoice number
     * @param invoiceNumber - The invoice number
     * @returns {Promise<any>}
     */
    getInvoiceDetails(invoiceNumber){
        let url = `${this._url}/invoices/${invoiceNumber}`;
        return this._fetchDetails(url);
    }

    /**
     * Invokes REST API to get the details of an invoice number
     * @param orderNumber - The order number
     * @returns {Promise<any>}
     */
    getCreditsDetails(orderNumber){
        let url = `${this._url}/credits/${orderNumber}`;
        return this._fetchDetails(url);
    }

    /**
     * Invokes REST API to get the details of an open order
     * @param orderNumber - The order number
     * @returns {Promise<any>}
     */
    getOpenOrderDetails(orderNumber){
        let url = `${this._url}/open/${orderNumber}`;
        return this._fetchDetails(url);
    }
    /**
     * Invokes REST API to get the details of an cancelled order
     * @param orderNumber - The order number
     * @returns {Promise<any>}
     */
    getCancelledOrderDetails(orderNumber){
        let url = `${this._url}/cancelled/${orderNumber}`;
        return this._fetchDetails(url);
    }

    /**
     * Returns the shipping tracking info for the invoice number requested. If the system does not have avalaible
     * the tracking number or the tracking number belongs to a carrier not yet supported, then
     * it will return null.
     * @param invoiceNumber
     * @returns {Promise<TrackingInfo>}
     */
    getShippingTrackingInfo(invoiceNumber) {

        let url = `${this._url}/invoices/${invoiceNumber}/tracking`;
        return new Promise((resolve, reject) => {
            try {
                let vm = this;

                HttpUtils.get(url, this._auth).then(data => {

                    if (data == null || data === false){
                        resolve(null);
                        return;
                    }

                    let trackingInfo = new TrackingInfo();
                    trackingInfo.trackingNumber = data.trackingNumber;
                    trackingInfo.carrier = data.carrier;
                    trackingInfo.url = data.url;
                    trackingInfo.invoiceNumber = invoiceNumber;

                    if (data.invoiceNumber !== invoiceNumber){
                        window.tracer.error(`[err-2HWAJK] [ORDER_HISTORY] [SHIPPING_TRACKING:${invoiceNumber}]:[INVOICE_MISMATCH]: Data discrepancy. Cannot proceed!`);
                        reject(new Error(`[err-MVGRL] [ORDER_HISTORY] [SHIPPING_TRACKING:${invoiceNumber}]:[INVOICE_MISMATCH]: Data discrepancy.`));
                        return;
                    }

                    resolve(trackingInfo);

                }).catch(error => {

                    window.tracer.error(`[err-5BGRZQ] [ORDER_HISTORY] [SHIPPING_TRACKING:${invoiceNumber}]: Error while getting tracking info from the server`);
                    if (error != null) {
                        window.tracer.error(error);
                    }

                    reject(error);
                });

            }
            catch(err){
                window.tracer.error(`[err-5BGRZQ] [ORDER_HISTORY] [SHIPPING_TRACKING:${invoiceNumber}]: Runtime error!`);

                if (error != null) {
                    window.tracer.error(error);
                }

                reject(error);
            }
        });
    }

    _fetchDetails(url){

        return new Promise((resolve, reject) => {

            try {

                let vm = this;

                HttpUtils.get(url, this._auth).then(data => {

                    let placedOrderDetails = vm._createPlacedOrderDetails(data);
                    resolve(placedOrderDetails);

                }).catch(error => {

                    window.tracer.error('[err-758559] [ORDER_HISTORY] Error while retrieving order history data');
                    if (error != null) {
                        window.tracer.error(error);
                    }

                    reject(error);
                });
            }
            catch(error){

                window.tracer.error('[err-650105] [ORDER_HISTORY] Runtime error!');

                if (error != null) {
                    window.tracer.error(error);
                }

                reject(error);
            }
        });

    }

    _createPlacedOrderDetails(data){

        if (data == null)return null;

        let obj = new PlacedOrderDetails();
        obj.orderNumber = data.orderNumber;
        obj.invoiceNumber = data.invoiceNumber;
        obj.purchaseOrder = data.purchaseOrder;
        obj.invoicedDate = data.invoicedDate;
        obj.total = data.orderTotal;

        let vm = this;
        data.parts.forEach(function(item) {

            let tmpPart = vm._createPlacedOrderPartsObject(item);
            if (tmpPart == null)return;

            obj.parts.push(tmpPart);
        });

        return obj;
    }

    _createPlacedOrderPartsObject(part){

        if (part == null){
            return null;
        }

        let obj = new PlacedOrderParts();
        obj.lineNumber = part.lineNumber;
        obj.partNumber = part.partNumber;
        obj.description = part.description;
        obj.quantity = part.orderQuantity;
        obj.price = part.unitPrice;
        obj.corePrice = part.corePrice;
        obj.extendedPrice = part.extendedPrice;
        obj.total = part.total;
        obj.branchId = part.branch.id;
        obj.branchName =  part.branch.name;
        obj.imageUrl = part.image.thumbnailUrl;

        if (part.shippedQuantity != null){
            obj.shippedQuantity = part.shippedQuantity;
        }

        return obj;

    }

    _fetchData(url){
        return new Promise((resolve, reject) => {

            axios.get(url, {headers: this._headers}).then(response => {
                resolve(response.data);
            }).catch((e) => {
                if (e.response) {
                    if (e.response.status === 404) {
                        reject();
                        return;
                    }
                }

                reject();
                window.tracer.error(e);
                HttpUtils.handleError(e);
            });
        });
    }



     _createParams(searchParams){


        let invoice = typeof searchParams.invoice != 'undefined' ? searchParams.invoice : '';
        let purchaseOrder = typeof searchParams.purchaseOrder != 'undefined' ? searchParams.purchaseOrder : '';
        let orderNumber = typeof searchParams.orderNumber != 'undefined' ? searchParams.orderNumber : '';
        let partNumber = typeof searchParams.partNumber != 'undefined' ? searchParams.partNumber : '';
        let serialNumber = typeof searchParams.serialNumber != 'undefined' ? searchParams.serialNumber : '';
        let page = typeof searchParams.page != 'undefined' ? searchParams.page : '';
        let months = typeof searchParams.months != 'undefined' ? searchParams.months : '';
        let startDate = typeof searchParams.startDate != 'undefined' ? searchParams.startDate : '';
        let endDate = typeof searchParams.endDate != 'undefined' ? searchParams.endDate : '';


        let urlParams = '';

        if (page === ''){
            page = '1';
        }

        if (invoice !== ''){
            urlParams = `${urlParams}field=invoice&value=${invoice}&page=${page}`;
            return urlParams;

        }
        if (purchaseOrder !== '')   {
            urlParams = `${urlParams}field=purchase-order&value=${purchaseOrder}&page=${page}`;
            return urlParams;
        }

        if (orderNumber !== '')   {
            urlParams = `${urlParams}field=order-number&value=${orderNumber}&page=${page}`;
            return urlParams;
        }

         if (partNumber !== '')   {
             urlParams = `${urlParams}field=part-number&value=${partNumber}&page=${page}`;
             return urlParams;
         }

         if (serialNumber !== '')   {
             urlParams = `${urlParams}field=serial-number&value=${serialNumber}&page=${page}`;
             return urlParams;
         }

         if (months !== '')   {
             urlParams = `${urlParams}months=${months}&page=${page}`;
             return urlParams;
         }

         if (startDate !== '' && endDate !== '')   {
             urlParams = `${urlParams}field=date-range&start=${startDate}&end=${endDate}&page=${page}`;
             return urlParams;
         }


        urlParams = `page=${page}`;
        return urlParams;
    }

     _getType = function(orderType){
        if (orderType === OrderHistoryService.Invoice){
            return "invoices";
        }

        if (orderType === OrderHistoryService.Credits){
            return "credits";
        }

        if (orderType === OrderHistoryService.Open){
            return "open-orders";
        }

        throw new Error("5NM98H: Invalid order type. ");

    };

}

