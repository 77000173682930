import {PdfDocumentService} from '@api/modules/pdfDocument/PdfDocumentService';
import {StoreProxy} from "@api/common/StoreProxy";
import {Tracer} from "@api/common/Tracer";

export default {
    name: "OrderDetailPdfViewer",
    data() {
        return {
            isLoading: false,
            pdfIdentifier: '',
            type: '',
            branch: '',
            existPdf: false,
            pdfUrl: '',
            sessionId: '',
            instanceId: ''
        }
    },
    created(){
        this.auth = StoreProxy.getAuth(this.$store);
        this._$pdfService = new PdfDocumentService(this.auth);
        this.instanceId = this.$instanceId;
        this.sessionId = this.auth.sessionId;
        Tracer.current.debug(`#22QUVJ:[AUTH]:[INSTANCE_ID=${this.instanceId}]:[SID:${this.sessionId}]"`);
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {

            let identifier = to.params.id;
            let type = to.params.type;
            let branch = to.query.branch;

            vm.pdfIdentifier = identifier;
            vm.type = type;
            vm.branch = branch;

            vm.isLoading = true;
            vm.checkPdf(() => {
                vm.isLoading = false;
            });
        });
        next();
    },
    beforeRouteUpdate(to, from, next) {
        next();
    },
    beforeRouteLeave(to, from, next) {

        next();
    },

    computed: {

    },
    methods: {
        checkPdf(onReady) {

            if (this.type === 'invoice') {
                let url = this._$pdfService.invoicePdfUrl(this.pdfIdentifier, this.sessionId, this.instanceId);
                Tracer.current.debug(`#22QUVJ:[INVOICE_PDF]:[URL=${url}]`);
                this._$pdfService.existsInvoicePdf(this.pdfIdentifier, result => {
                    this.existPdf = result;
                    if (result) {
                        this.pdfUrl = url;
                    }

                    onReady();
                })
            }
            if (this.type === 'returns' || this.type === 'credits') {
                let url = this._$pdfService.creditPdfUrl(this.pdfIdentifier, this.sessionId, this.instanceId);
                Tracer.current.debug(`#22QUVJ:[CREDITS_PDF]:[URL=${url}]`);
                this._$pdfService.existsCreditPdf(this.pdfIdentifier, result => {
                    this.existPdf = result;
                    if (result) {
                        this.pdfUrl = url;
                    }

                    onReady();
                })
            }
            if (this.type === 'open' || this.type === 'openOrders') {
                let url = this._$pdfService.statementPdfUrl(this.pdfIdentifier, this.sessionId, this.instanceId);
                Tracer.current.debug(`#22QUVJ:[OPEN_ORDERS_PDF]:[URL=${url}]`);
                this._$pdfService.existsStatementPdf(this.pdfIdentifier, result => {
                    this.existPdf = result;
                    if (result) {
                        this.pdfUrl = url;
                    }

                    onReady();
                })
            }
            if (this.type === 'statement') {
                let url = this._$pdfService.statementPdfUrl(this.pdfIdentifier, this.sessionId, this.instanceId);
                this._$pdfService.existsStatementPdf(this.pdfIdentifier, result => {
                    this.existPdf = result;
                    if (result) {
                        this.pdfUrl = url;
                    }

                    onReady();
                })
            }
        },

    }
}