/**
 * Represents Address information for placing order.
 * <br><br>
 *
 */
export class Address {

    constructor(){

        this._customerName = '';
        this._address1 = '';
        this._address2 = '';
        this._city = '';
        this._state = '';
        this._zipCode = ''
    }


    setAddress(customer, addr1, addr2, city, state, zipCode){
        this._customerName = customer;
        this._address1 = addr1;
        this._address2 = addr2;
        this._city = city;
        this._state = state;
        this._zipCode = zipCode;
    }

    set customer(value){
        this._customerName = value;
    }

    get customer(){
       return this._customerName;
    }

    set address(value){
        this._address1 = value;
    }

    get address(){
       return this._address1;
    }

    set address2(value){
        this._address2 = value;
    }

    get address2() {
        return this._address2;
    }

    set city(value){
        this._city = value;
    }

    get city(){
        return this._city;
    }

    set state(value){
        this._state = value;
    }

    get state(){
        return this._state;
    }

    set zipCode(value)
    {
        this._zipCode = value;
    }


    get zipCode(){
        return this._zipCode;
    }





}