import {OrderHistoryService} from '@api/modules/orderHistory/services/OrderHistoryService';
import {OrderDetails} from "@api/modules/orderHistory/OrderDetails";
import {StoreProxy} from "@api/common/StoreProxy";

export default {
    name: "OrderDetailView",
    beforeRouteEnter(to, from, next) {
        next(vm => {

            let orderNumber = to.params.orderNumber;
            let type = to.params.type;
            vm.onEnter(type, orderNumber);
        });
        next();
    },
    beforeRouteUpdate(to, from, next) {
        let orderNumber = to.params.orderNumber;
        let type = to.params.type;
        this.onEnter(type, orderNumber);
    },
    beforeRouteLeave(to, from, next) {
        next();
    },

    data() {
        return {

            orderNumber: '',
            type: '',
            order: {},
            shippingTrackingUrl: '',
            isLoading: false,
            error: false
        }
    },
    computed:{

        notes(){
            if (!this.order || !this.order.notes)return [];
            return this.order.notes;
        },
        totalColSpan(){

            if (this.type === 'open' || this.type === 'openOrders')
            {
                return 10;
            }
            else{
                return 9;
            }

        },
        showShippedQuantityColumn(){
            return (this.type === 'open' || this.type === 'openOrders');
        },
        showInvoiceHeader(){
            return !(this.type === 'open' || this.type === 'openOrders');
        },
        hasTrackingInfo(){
            return this.shippingTrackingUrl !== '';
        }

    },
    created() {
        this._auth = StoreProxy.getAuth(this.$store);
        this._orderHistoryService = new OrderHistoryService(this._auth);
    },
    filters:{
      empty : function(value){
          if (!value) return 'N/A';
          if (value.length === 0) return 'N/A';
          return value;
      }
    },
    methods: {
        onEnter(type, orderNumber){

            this.orderNumber = orderNumber;
            this.type = type;

            if (orderNumber != null && orderNumber !== ''){
                this.loadOrder(type, orderNumber);
            }
        },
        print(){
            window.print();
        },
        loadOrder(type, identifier){

            let orderDetails = new OrderDetails(this._auth);
            let searchType = '';
            let promise = null;
            let vm = this;

            this.isLoading = true;


            if (type === 'invoice'){
                let invoiceNumber = identifier;

                window.tracer.debug(`[dbg-953092] [ORDER_DETAILS]:[TYPE:INVOICE:${invoiceNumber}] Loading invoice.`);
                searchType = OrderHistoryService.Invoice;
                promise = orderDetails.getInvoiceDetails(invoiceNumber);

                this._orderHistoryService.getShippingTrackingInfo(invoiceNumber).then(trackingInfo => {

                    if (trackingInfo != null){
                        vm.shippingTrackingUrl = trackingInfo.url;
                    }
                    else {
                        vm.shippingTrackingUrl = '';
                    }

                }).catch(err => {
                    vm.shippingTrackingUrl = '';
                    window.tracer.error(`[err-9UNQVS] [ORDER_DETAILS]:[TYPE:INVOICE:${invoiceNumber}]::[ERROR]: Error getting the shipping tracking information.`);
                });

            }

            if (type === 'credits' || type === 'returns'){

                let orderNumber = identifier;
                window.tracer.debug(`[dbg-888950] loading order details for credit ${orderNumber}`);
                searchType = OrderHistoryService.Credits;
                promise = orderDetails.getCreditsDetails(orderNumber);
            }

            if (type === 'open' || type === 'openOrders') {

                let orderNumber = identifier;
                searchType = OrderHistoryService.Open;
                promise = orderDetails.getOpenOrderDetails(orderNumber);
            }

            if (!promise){
                throw new Error('[err-229138] Expected a promise. We got un undefined value!');
            }

            promise.then(order => {

                this.order = order;
                this.isLoading = false;

            }).catch(rej => {

                this.error = true;
                window.tracer.error('[err-276167] error retrieving order');
                this.isLoading = false;

            });


        },
        orderStatusDescription(status){
            if (status === 'Complete')return 'Completed';
            if (status === 'Picked')return 'Picked';
            if (status === 'Shipped')return 'Shipped';
            if (status === 'CreditHold')return 'Credit Hold';
            if (status === 'Delivered')return 'Delivered';
            if (status === 'Undefined')return '';
        },
        trackPackage(){
            window.open(this.shippingTrackingUrl, '_blank');
        }

    }

}
